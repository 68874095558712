<template>
    <!-- 详情页 -->
    <div class="vyDetail">
        <div class="vydBox">
            <!-- 左边主体区域 -->
            <div class="vydLeft">
                <!-- 商品信息 -->
                <div class="vlBox">
                    <div class="vlbTop">
                        <!-- 左边图片区域 -->
                        <div class="vlLeft">
                            <img
                                src="https://market-isv-1258344699.file.myqcloud.com/20220104/images/2019/05/40cac7727332385667198f85b3f7a711.png"
                                alt=""
                                class="vllBig"
                            />
                            <img
                                src="https://market-isv-1258344699.file.myqcloud.com/20220104/images/2019/05/40cac7727332385667198f85b3f7a711.png"
                                alt=""
                                class="vllSmall"
                            />
                        </div>
                        <!-- 右边信息区域 -->
                        <div class="vlRight">
                            <p class="vlr1">电商零售行业模板-友好搭建</p>
                            <p class="vlr2">
                                友好速搭商城建站，无需开发与设计，60秒上线高品质网站，多类行业模板，跨屏适配PC、平板
                                、手机和微站、小程序，随心所欲调整布局，无处不在的流畅体验。
                            </p>
                            <div class="vlr3">
                                <div class="vlr3Box1">
                                    <div class="vb11">
                                        <p class="vbP">价格</p>
                                        <p class="vbP">续费价格</p>
                                    </div>
                                    <div class="vb12">
                                        <p class="vbP2">威有1</p>
                                        <p class="vbP">3988元/年</p>
                                    </div>
                                </div>
                                <div class="vlr3Box2">
                                    <p class="vb2P">6148人在使用</p>
                                    <div class="vb2X">
                                        <img
                                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-star-actived.svg"
                                            alt=""
                                            class="vxx1"
                                        />
                                        <img
                                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-star-actived.svg"
                                            alt=""
                                            class="vxx1"
                                        />
                                        <img
                                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-star-actived.svg"
                                            alt=""
                                            class="vxx1"
                                        />
                                        <img
                                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-star-half.svg"
                                            alt=""
                                            class="vxx1"
                                        />
                                        <img
                                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-star-empty.svg"
                                            alt=""
                                            class="vxx1"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="vlr4">
                                <p class="v4P1">支付方式</p>
                                <p class="v4P2">
                                    SaaS支付
                                    <img
                                        src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-infobubble.png"
                                        alt=""
                                        class="v4Img"
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="vlbBottom">
                        <ul>
                            <li class="vbbLi">
                                <img
                                    src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/indemnity-1.png"
                                    alt=""
                                    class="vbbImg"
                                />
                                <p class="vbbP">优质服务商家</p>
                            </li>
                            <li class="vbbLi">
                                <img
                                    src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/indemnity-2.png"
                                    alt=""
                                    class="vbbImg"
                                />
                                <p class="vbbP">7*8小时在线客服</p>
                            </li>
                            <li class="vbbLi">
                                <img
                                    src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/indemnity-3.png"
                                    alt=""
                                    class="vbbImg"
                                />
                                <p class="vbbP">专业测试保证品质</p>
                            </li>
                            <li class="vbbLi">
                                <img
                                    src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/indemnity-4.png"
                                    alt=""
                                    class="vbbImg"
                                />
                                <p class="vbbP">售后无忧</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- 图文介绍 -->
                <div class="vlBox2">
                    <div class="vlb2Top">
                        <ul>
                            <li class="v2tLi">商品详情</li>
                            <li class="v2tLi">使用指南</li>
                            <li class="v2tLi">累计评价（56）</li>
                            <li class="v2tLi">售后服务</li>
                        </ul>
                    </div>
                    <div class="vlb2Data">
                        <div class="v2dText">
                            <p class="v2dt1">商品参数</p>
                            <div class="v2dt2">
                                <p class="v22">支付方式</p>
                                <p class="v222">Saas交付</p>
                            </div>
                        </div>
                        <div class="v2dImgs">
                            <p class="v2dt1">商品介绍</p>
                            <div class="v2diList">
                                <ul>
                                    <li class="v2lLi">
                                        <img
                                            src="https://market-isv-1258344699.file.myqcloud.com/20220104/images/2019/10/5e0f4b5dadf10a22d0fe490da0b5e1ac.jpg"
                                            alt=""
                                            class="v2lImg"
                                        />
                                    </li>
                                    <li class="v2lLi">
                                        <img
                                            src="https://market-isv-1258344699.file.myqcloud.com/20220104/images/2019/10/b76ab1f2204b87d97a9085b48db0770c.png"
                                            alt=""
                                            class="v2lImg"
                                        />
                                    </li>
                                    <li class="v2lLi">
                                        <img
                                            src="https://market-isv-1258344699.file.myqcloud.com/20220104/images/2019/11/c7a375a0d7fc7795f900d32eb6cbed56.png"
                                            alt=""
                                            class="v2lImg"
                                        />
                                    </li>
                                    <li class="v2lLi">
                                        <img
                                            src="https://market-isv-1258344699.file.myqcloud.com/20220104/images/2019/11/8a2f45e361d3703dec01d8e9ba1f619d.png"
                                            alt=""
                                            class="v2lImg"
                                        />
                                    </li>
                                    <li class="v2lLi">
                                        <img
                                            src="https://market-isv-1258344699.file.myqcloud.com/20220104/images/2019/11/0ebc530ad2fbe578018012a2d5edef47.png"
                                            alt=""
                                            class="v2lImg"
                                        />
                                    </li>
                                    <li class="v2lLi">
                                        <img
                                            src="https://market-isv-1258344699.file.myqcloud.com/20220104/images/2019/11/2ac66378ecdc7b921822cc06a3a4346d.png"
                                            alt=""
                                            class="v2lImg"
                                        />
                                    </li>
                                    <li class="v2lLi">
                                        <img
                                            src="https://market-isv-1258344699.file.myqcloud.com/20220104/images/2019/10/2b8ab0a1ece7471c090acb9e0aa43cdc.png"
                                            alt=""
                                            class="v2lImg"
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="v2dImgs2">
                            <ul>
                                <li class="v2dLi">
                                    <p class="v2l2P">使用指南</p>
                                    <div class="v2lN">
                                        <p class="v2lN1">1</p>
                                        <p class="v2lN2">想要完成后，点击进入控制台</p>
                                    </div>
                                    <div class="v2lImg">
                                        <img src="http://iv.vu818.com/img/test8.jpg" alt="" class="v2iImg" />
                                    </div>
                                </li>
                                <li class="v2dLi">
                                    <div class="v2lN">
                                        <p class="v2lN1">2</p>
                                        <p class="v2lN2">在控制台中点击“管理”查看商品详情</p>
                                    </div>
                                    <div class="v2lImg">
                                        <img src="http://iv.vu818.com/img/test9.jpg" alt="" class="v2iImg" />
                                    </div>
                                </li>
                                <li class="v2dLi">
                                    <div class="v2lN">
                                        <p class="v2lN1">3</p>
                                        <p class="v2lN2">查看软件访问地址，开始使用软件</p>
                                    </div>
                                    <div class="v2lImg">
                                        <img src="http://iv.vu818.com/img/test10.jpg" alt="" class="v2iImg" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="v2dBottom">
                            <div class="v2db1">
                                <ul>
                                    <li class="vdb1Li">售后服务</li>
                                    <li class="vdb1Li">投诉举报</li>
                                    <li class="vdb1Li">联系客服</li>
                                    <li class="vdb1Li">联系威有</li>
                                </ul>
                            </div>
                            <p class="v2db2">
                                为保障您的权益，请勿线下交易！90%的欺诈、纠纷 、资金盗窃均由线下交易导致。威有云市场为您提供以下服务保障：
                            </p>
                            <div class="v2db3">
                                <ul>
                                    <li class="vdb2Li">
                                        <img
                                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/indemnity-1.png"
                                            alt=""
                                            class="v2lIcon"
                                        />
                                        <div class="v2lText">
                                            <p class="vt1">优质服务商家</p>
                                            <p class="vt2">严格准入 提供海量上云服务</p>
                                        </div>
                                    </li>
                                    <li class="vdb2Li">
                                        <img
                                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/indemnity-2.png"
                                            alt=""
                                            class="v2lIcon"
                                        />
                                        <div class="v2lText">
                                            <p class="vt1">7*8小时在线客服</p>
                                            <p class="vt2">全程在线咨询 助您安心购物</p>
                                        </div>
                                    </li>
                                    <li class="vdb2Li">
                                        <img
                                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/indemnity-3.png"
                                            alt=""
                                            class="v2lIcon"
                                        />
                                        <div class="v2lText">
                                            <p class="vt1">专业测试保证品质</p>
                                            <p class="vt2">安全监测 保障商品质量</p>
                                        </div>
                                    </li>
                                    <li class="vdb2Li">
                                        <img
                                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/indemnity-4.png"
                                            alt=""
                                            class="v2lIcon"
                                        />
                                        <div class="v2lText">
                                            <p class="vt1">售后无忧</p>
                                            <p class="vt2">不满意可退款 保障售后无忧</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 评价 -->
                <div class="vlAssess">
                    <div class="vlaBox">
                        <div class="vlaTop">累计评价（56）</div>
                        <div class="vlaBox2">
                            <div class="vlaTabs">
                                <div class="vt1">
                                    <p class="v1P1">综合评分</p>
                                    <p class="v1P2">3.7</p>
                                    <div class="v1x">
                                        <img
                                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-star-actived.svg"
                                            alt=""
                                            class="v1x1"
                                        />
                                        <img
                                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-star-actived.svg"
                                            alt=""
                                            class="v1x1"
                                        />
                                        <img
                                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-star-actived.svg"
                                            alt=""
                                            class="v1x1"
                                        />
                                        <img
                                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-star-half.svg"
                                            alt=""
                                            class="v1x1"
                                        />
                                        <img
                                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-star-empty.svg"
                                            alt=""
                                            class="v1x1"
                                        />
                                    </div>
                                </div>
                                <div class="vt2">
                                    <ul>
                                        <li class="vt2Li" v-for="item in 16" :key="item">全部（56）</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="vlaContent">
                                <ul>
                                    <li class="vcLi" v-for="item in 10" :key="item">
                                        <div class="vcl1">
                                            <p class="v11">10***45</p>
                                            <p class="v12">2020-11-08</p>
                                        </div>
                                        <div class="vcl2">
                                            <div class="v2Top">
                                                <div class="v1x">
                                                    <img
                                                        src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-star-actived.svg"
                                                        alt=""
                                                        class="v1x1"
                                                    />
                                                    <img
                                                        src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-star-actived.svg"
                                                        alt=""
                                                        class="v1x1"
                                                    />
                                                    <img
                                                        src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-star-half.svg"
                                                        alt=""
                                                        class="v1x1"
                                                    />
                                                    <img
                                                        src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-star-empty.svg"
                                                        alt=""
                                                        class="v1x1"
                                                    />
                                                    <img
                                                        src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-star-empty.svg"
                                                        alt=""
                                                        class="v1x1"
                                                    />
                                                </div>
                                                <p class="v2P">产品难用</p>
                                            </div>
                                            <div class="v2Bottom">
                                                <p class="vbP">规格：体验版 周期：1个月</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="vlaBox3">
                            <el-pagination background layout="prev, pager, next" :total="1000"> </el-pagination>
                        </div>
                    </div>
                </div>
                <!-- 猜你需要 -->
                <div class="vllGuess">
                    <div class="vgBox">
                        <div class="vgbP">猜你需要</div>
                        <div class="vgbList">
                            <ul>
                                <li class="vgbLi" v-for="item in 8" :key="item">
                                    <div class="vgImgt">
                                        <img
                                            src="https://market-isv-1258344699.file.myqcloud.com/20220104/images/2019/05/77f26e568bcbbb71cdf47871e2f7c0df.png"
                                            alt=""
                                            class="vgImg"
                                        />
                                    </div>
                                    <p class="vgP1">电商零售行业模板 全网服务...</p>
                                    <p class="vgP3">威有1</p>
                                    <p class="vgP2">356人在用</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 右边区域 -->
            <div class="vydRight">
                <div class="vrBox1">
                    <p class="vrb1">亿众骏达网络科技（深圳）有限公司</p>
                    <div class="vrb2">
                        <img
                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-concat-qq.png"
                            alt=""
                            class="vrbIcon"
                        />
                        <p class="vrbP">立即咨询</p>
                    </div>
                    <div class="vrb2">
                        <img
                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-concat-tel.png"
                            alt=""
                            class="vrbIcon"
                        />
                        <p class="vrbP">0755-83051027</p>
                    </div>
                    <div class="vrb2">
                        <img
                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-concat-email.png"
                            alt=""
                            class="vrbIcon"
                        />
                        <p class="vrbP">support@youhaosuda.com</p>
                    </div>
                    <div class="vrb3">
                        <div class="vrb31">
                            <img
                                src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-concat-time.png"
                                alt=""
                                class="vrbIcon"
                            />
                            <p class="vrbP">卖家服务时间</p>
                        </div>
                        <p class="vrb32">09:00:00-21:00:00</p>
                    </div>
                </div>
                <div class="vrBox2">
                    <img
                        src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-consumer-warn.png"
                        alt=""
                        class="vrbIcon"
                    />
                    <div class="vrbText">为保障您的权益，请勿线下交易！90%的欺诈、纠纷、资金盗窃均由线下交易导致。</div>
                </div>
                <div class="vrBox3">
                    <div class="vrb3Top">
                        <span>荐</span>
                        <p class="v3t">· 同类其他商品</p>
                    </div>
                    <ul>
                        <li class="vlrLi" v-for="item in 5" :key="item">
                            <div class="vlrImgt">
                                <img
                                    src="https://market-isv-1258344699.file.myqcloud.com/20220104/images/2019/09/5d74186e566314e01a5d5d7acec4c354.png"
                                    alt=""
                                    class="vlrImg"
                                />
                            </div>
                            <p class="vlrP">IT互联网行业模板-友好速搭</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VyDetail',
};
</script>

<style lang="less" scoped>
// 详情页
.vyDetail {
    width: 100%;
    height: 10700px;
    margin-top: 100px;
    .vydBox {
        width: 1138px;
        height: 100%;
        margin: 0 auto;
        padding-top: 20px;
        // 左边主体区域
        .vydLeft {
            width: 81%;
            height: 100%;
            float: left;
            // 商品信息
            .vlBox {
                width: 95%;
                height: 400px;
                margin: 0 auto;
                margin-top: 20px;
                background-color: #fff;
                border-radius: 20px;
                box-shadow: 1px 1px 10px 1px gainsboro;
                .vlbTop {
                    width: 100%;
                    height: 300px;
                    // 左边图片区域
                    .vlLeft {
                        width: 30%;
                        height: 100%;
                        float: left;
                        .vllBig {
                            width: 100%;
                            height: 192px;
                            border-top-left-radius: 20px;
                        }
                        .vllSmall {
                            width: 64px;
                            height: 44px;
                            margin-top: 5px;
                        }
                    }
                    // 右边信息区域
                    .vlRight {
                        width: 68%;
                        height: 100%;
                        float: right;
                        .vlr1 {
                            width: 100%;
                            height: 20px;
                            line-height: 20px;
                            font-size: 14px;
                            font-weight: 900;
                            margin-top: 10px;
                        }
                        .vlr2 {
                            width: 100%;
                            height: 36px;
                            font-size: 12px;
                            line-height: 20px;
                            margin-top: 10px;
                        }
                        .vlr3 {
                            width: 100%;
                            height: 83px;
                            margin-top: 20px;
                            background-color: #fff3ed;
                            .vlr3Box1 {
                                width: 449px;
                                height: 60px;
                                margin-top: 10px;
                                float: left;
                                .vb11 {
                                    width: 68px;
                                    height: 100%;
                                    float: left;
                                    font-size: 12px;
                                    .vbP {
                                        width: 100%;
                                        height: 50%;
                                        line-height: 30px;
                                        padding-left: 10px;
                                    }
                                }
                                .vb12 {
                                    width: 68px;
                                    height: 100%;
                                    float: left;
                                    margin-left: 20px;
                                    line-height: 30px;
                                    .vbP2 {
                                        width: 100%;
                                        height: 50%;
                                        font-size: 24px;
                                        color: #f43f2d;
                                    }
                                    .vbP {
                                        width: 100%;
                                        height: 50%;
                                        font-size: 12px;
                                    }
                                }
                            }
                            .vlr3Box2 {
                                width: 120px;
                                height: 60px;
                                margin-top: 10px;
                                border-left: 1px solid gainsboro;
                                float: left;
                                line-height: 30px;
                                .vb2P {
                                    width: 100%;
                                    height: 50%;
                                    font-size: 12px;
                                    text-align: center;
                                }
                                .vb2X {
                                    width: 100%;
                                    height: 14px;
                                    padding-left: 25px;
                                    margin-top: 5px;
                                    box-sizing: border-box;
                                    .vxx1 {
                                        width: 14px;
                                        height: 14px;
                                        float: left;
                                    }
                                }
                            }
                        }
                        .vlr4 {
                            width: 100%;
                            height: 20px;
                            margin-top: 20px;
                            line-height: 20px;
                            font-size: 14px;
                            .v4P1 {
                                float: left;
                                color: gray;
                            }
                            .v4P2 {
                                float: left;
                                margin-left: 20px;
                                .v4Img {
                                    width: 14px;
                                    height: 14px;
                                }
                            }
                        }
                    }
                }
                .vlbBottom {
                    width: 100%;
                    height: 100px;
                    ul {
                        border-top: 1px solid gainsboro;
                        width: 90%;
                        height: 50%;
                        margin: 0 auto;
                        padding-top: 25px;
                        display: flex;
                        justify-content: space-around;
                        .vbbLi {
                            min-width: 109px;
                            height: 46px;
                            line-height: 46px;
                            color: #0878d0;
                            .vbbImg {
                                width: 50px;
                                height: 46px;
                                float: left;
                            }
                            .vbbP {
                                font-size: 14px;
                                float: left;
                            }
                        }
                    }
                }
            }
            // 图文介绍
            .vlBox2 {
                width: 95%;
                min-height: 2000px;
                margin: 0 auto;
                margin-top: 20px;
                background-color: #fff;
                border-radius: 20px;
                box-shadow: 1px 1px 10px 1px gainsboro;
                .vlb2Top {
                    width: 95%;
                    height: 50px;
                    margin: 0 auto;
                    border-bottom: 1px solid gainsboro;
                    ul {
                        width: 100%;
                        height: 100%;
                        line-height: 50px;
                        .v2tLi {
                            min-width: 76px;
                            height: 50px;
                            float: left;
                            margin-left: 20px;
                            font-size: 14px;
                            cursor: pointer;
                        }
                        .v2tLi:hover {
                            color: #0878d0;
                        }
                    }
                }
                .vlb2Data {
                    width: 95%;
                    height: 95%;
                    margin: 0 auto;
                    margin-top: 20px;
                    .v2dText {
                        width: 100%;
                        height: 60px;
                        line-height: 30px;
                        .v2dt1 {
                            width: 100%;
                            height: 30px;
                            font-size: 14px;
                        }
                        .v2dt2 {
                            width: 100%;
                            height: 30px;
                            font-size: 12px;
                            .v22 {
                                float: left;
                                color: gray;
                            }
                            .v222 {
                                float: left;
                                margin-left: 20px;
                            }
                        }
                    }
                    .v2dImgs {
                        width: 100%;
                        margin-top: 30px;
                        .v2dt1 {
                            width: 100%;
                            height: 30px;
                            font-size: 14px;
                        }
                        .v2diList {
                            width: 100%;
                            min-height: 6000px;
                            ul {
                                width: 100%;
                                height: 100%;
                                .v2lLi {
                                    width: 100%;
                                    min-height: 240px;
                                    .v2lImg {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }
                    .v2dImgs2 {
                        width: 100%;
                        height: 1600px;
                        margin-top: 10px;
                        ul {
                            width: 100%;
                            height: 100%;
                            .v2dLi {
                                width: 100%;
                                min-height: 350px;
                                .v2l2P {
                                    width: 100%;
                                    height: 24%;
                                    line-height: 24px;
                                    font-size: 18px;
                                    font-weight: 700;
                                }
                                .v2lN {
                                    width: 100%;
                                    height: 27px;
                                    margin-top: 20px;
                                    line-height: 27px;
                                    .v2lN1 {
                                        width: 27px;
                                        height: 27px;
                                        text-align: center;
                                        border-radius: 50%;
                                        background-color: #34a4fc;
                                        color: white;
                                        float: left;
                                    }
                                    .v2lN2 {
                                        float: left;
                                        margin-left: 10px;
                                        font-size: 12px;
                                    }
                                }
                                .v2lImg {
                                    width: 100%;
                                    min-height: 295px;
                                    .v2iImg {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }
                    .v2dBottom {
                        width: 95%;
                        height: 225px;
                        margin: 0 auto;
                        .v2db1 {
                            width: 100%;
                            height: 24px;
                            ul {
                                width: 100%;
                                height: 100%;
                                .vdb1Li {
                                    width: 56px;
                                    height: 16px;
                                    line-height: 16px;
                                    font-size: 14px;
                                    margin-left: 20px;
                                    margin-top: 4px;
                                    float: left;
                                    color: #34a4fc;
                                }
                                .vdb1Li:nth-child(1) {
                                    width: 73px;
                                    height: 24px;
                                    line-height: 24px;
                                    font-size: 16px;
                                    margin-top: 0;
                                    color: black;
                                    border-right: 1px solid gainsboro;
                                }
                            }
                        }
                        .v2db2 {
                            width: 100%;
                            height: 18px;
                            line-height: 18px;
                            font-size: 12px;
                            margin-top: 20px;
                            padding-left: 20px;
                        }
                        .v2db3 {
                            width: 100%;
                            height: 73px;
                            margin-top: 30px;
                            ul {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: space-around;
                                .vdb2Li {
                                    width: 24%;
                                    height: 73px;
                                    border-radius: 5px;
                                    background-color: #eee;
                                    .v2lIcon {
                                        width: 25px;
                                        height: 25px;
                                        float: left;
                                        margin-top: 25px;
                                        margin-left: 2px;
                                    }
                                    .v2lText {
                                        width: 80%;
                                        height: 40px;
                                        line-height: 20px;
                                        font-size: 12px;
                                        float: left;
                                        margin-top: 15px;
                                        margin-left: 8px;
                                        .vt1 {
                                            width: 100%;
                                            height: 20px;
                                        }
                                        .vt2 {
                                            width: 100%;
                                            height: 20px;
                                            color: gray;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            // 评价
            .vlAssess {
                width: 95%;
                height: 1500px;
                margin-top: 30px;
                margin-left: 20px;
                border-radius: 20px;
                box-shadow: 1px 1px 10px 1px gainsboro;
                .vlaBox {
                    width: 90%;
                    height: 100%;
                    margin: 0 auto;
                    .vlaTop {
                        width: 100%;
                        height: 25px;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 25px;
                        padding-top: 20px;
                    }
                    .vlaBox2 {
                        width: 100%;
                        height: 1300px;
                        margin-top: 20px;
                        border: 1px solid gainsboro;
                        border-radius: 20px;
                        .vlaTabs {
                            width: 95%;
                            height: 183px;
                            margin: 0 auto;
                            margin-top: 20px;
                            border-bottom: 1px solid gainsboro;
                            .vt1 {
                                width: 120px;
                                height: 100px;
                                text-align: center;
                                float: left;
                                margin-top: 35px;
                                margin-left: 10px;
                                .v1P1 {
                                    width: 100%;
                                    height: 20px;
                                    line-height: 20px;
                                    font-size: 12px;
                                }
                                .v1P2 {
                                    width: 100%;
                                    height: 35px;
                                    font-size: 24px;
                                    line-height: 35px;
                                    margin-top: 10px;
                                    color: #ff5746;
                                }
                                .v1x {
                                    width: 100%;
                                    height: 14px;
                                    margin-top: 10px;
                                    .v1x1 {
                                        width: 14px;
                                        height: 14px;
                                    }
                                }
                            }
                            .vt2 {
                                width: 600px;
                                height: 130px;
                                margin-top: 20px;
                                margin-left: 5px;
                                float: left;
                                ul {
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    flex-wrap: wrap;
                                    .vt2Li {
                                        min-width: 75px;
                                        height: 25px;
                                        line-height: 25px;
                                        margin-left: 10px;
                                        margin-top: 10px;
                                        font-size: 12px;
                                        text-align: center;
                                        color: #34a4fc;
                                        border: 1px solid #34a4fc;
                                    }
                                }
                            }
                        }
                        .vlaContent {
                            width: 95%;
                            height: 1050px;
                            margin: 0 auto;
                            margin-top: 10px;
                            ul {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                .vcLi {
                                    width: 100%;
                                    height: 110px;
                                    border-bottom: 1px solid gainsboro;
                                    .vcl1 {
                                        width: 110px;
                                        height: 40px;
                                        float: left;
                                        margin-top: 30px;
                                        margin-left: 10px;
                                        .v11,
                                        .v12 {
                                            width: 100%;
                                            height: 20px;
                                            line-height: 20px;
                                            font-size: 12px;
                                        }
                                        .v12 {
                                            color: gray;
                                        }
                                    }
                                    .vcl2 {
                                        width: 600px;
                                        height: 50px;
                                        line-height: 25px;
                                        float: left;
                                        margin-left: 15px;
                                        margin-top: 25px;
                                        .v2Top {
                                            width: 100%;
                                            height: 25px;
                                            .v1x {
                                                width: 100px;
                                                height: 14px;
                                                float: left;
                                                .v1x1 {
                                                    width: 14px;
                                                    height: 14px;
                                                }
                                            }
                                            .v2P {
                                                float: left;
                                                font-size: 12px;
                                            }
                                        }
                                        .v2Bottom {
                                            width: 100%;
                                            height: 25px;
                                            .vbP {
                                                font-size: 12px;
                                                color: gray;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .vlaBox3 {
                        width: 100%;
                        height: 35px;
                        margin-top: 40px;
                        padding-left: 380px;
                        box-sizing: border-box;
                    }
                }
            }
            // 猜你需要
            .vllGuess {
                width: 95%;
                height: 592px;
                margin-top: 30px;
                margin-left: 20px;
                border-radius: 20px;
                background-color: #fff;
                box-shadow: 1px 1px 10px 1px gainsboro;
                .vgBox {
                    width: 95%;
                    height: 100%;
                    margin: 0 auto;
                    .vgbP {
                        width: 100%;
                        height: 25px;
                        line-height: 25px;
                        font-size: 24px;
                        padding-top: 20px;
                    }
                    .vgbList {
                        width: 100%;
                        height: 520px;
                        margin-top: 25px;
                        ul {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-around;
                            .vgbLi {
                                width: 23%;
                                height: 240px;
                                border-radius: 10px;
                                box-shadow: 1px 1px 10px 1px gainsboro;
                                .vgImgt {
                                    width: 100%;
                                    height: 60%;
                                    overflow: hidden;
                                    border-top-left-radius: 10px;
                                    border-top-right-radius: 10px;
                                    .vgImg {
                                        width: 100%;
                                        height: 100%;
                                    }
                                    .vgImg:hover {
                                        transform: scale(1.05);
                                        transition: 0.5s;
                                    }
                                }
                                .vgP1 {
                                    width: 100%;
                                    height: 20px;
                                    line-height: 20px;
                                    font-size: 14px;
                                    margin-top: 10px;
                                    text-align: center;
                                }
                                .vgP2 {
                                    width: 100%;
                                    height: 12px;
                                    line-height: 12px;
                                    font-size: 12px;
                                    margin-top: 10px;
                                    padding-left: 120px;
                                    box-sizing: border-box;
                                    color: #e14d42;
                                }
                                .vgP3 {
                                    width: 100%;
                                    height: 20px;
                                    font-size: 20px;
                                    margin-top: 10px;
                                    color: #f43f2d;
                                    font-weight: 900;
                                }
                            }
                            .vgbLi:hover {
                                box-shadow: 2px 2px 10px 2px gray;
                                transition: 0.5s;
                            }
                        }
                    }
                }
            }
        }
        // 右边区域
        .vydRight {
            width: 18%;
            height: 65%;
            float: right;
            padding-top: 20px;
            .vrBox1 {
                width: 100%;
                height: 245px;
                border-radius: 10px;
                background-color: #fff;
                box-shadow: 1px 1px 10px 1px gainsboro;
                .vrb1 {
                    width: 90%;
                    height: 36px;
                    margin: 0 auto;
                    padding-top: 10px;
                    line-height: 18px;
                    font-size: 14px;
                }
                .vrb2 {
                    width: 90%;
                    height: 20px;
                    margin: 0 auto;
                    margin-top: 10px;
                    line-height: 20px;
                    .vrbP {
                        font-size: 12px;
                        float: left;
                        margin-left: 5px;
                    }
                    .vrbIcon {
                        width: 20px;
                        height: 20px;
                        float: left;
                    }
                }
                .vrb3 {
                    width: 90%;
                    height: 70px;
                    margin: 0 auto;
                    margin-top: 30px;
                    border-top: 1px solid gainsboro;
                    .vrb31 {
                        width: 100%;
                        height: 20px;
                        padding-top: 20px;
                        .vrbP {
                            font-size: 12px;
                            float: left;
                            margin-left: 5px;
                        }
                        .vrbIcon {
                            width: 20px;
                            height: 20px;
                            float: left;
                        }
                    }
                    .vrb32 {
                        width: 80%;
                        height: 25px;
                        line-height: 25px;
                        color: #ff7100;
                        font-size: 16px;
                        margin-left: 20px;
                    }
                }
            }
            .vrBox2 {
                width: 100%;
                height: 90px;
                margin-top: 20px;
                border-radius: 10px;
                background-color: #e1f2ff;
                position: relative;
                .vrbIcon {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 10px;
                    left: 20px;
                }
                .vrbText {
                    width: 140px;
                    height: 80%;
                    margin-top: 10px;
                    margin-right: 10px;
                    font-size: 12px;
                    float: right;
                }
            }
            .vrBox3 {
                width: 90%;
                height: 880px;
                margin: 0 auto;
                margin-top: 25px;
                .vrb3Top {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    padding-top: 10px;
                    span {
                        width: 24px;
                        height: 30px;
                        font-size: 24px;
                        float: left;
                        color: #f2a056;
                    }
                    .v3t {
                        margin-left: 10px;
                        font-size: 16px;
                        float: left;
                    }
                }
                ul {
                    width: 100%;
                    height: 650px;
                    margin-top: 80px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    .vlrLi {
                        width: 100%;
                        height: 157px;
                        .vlrImgt {
                            width: 100%;
                            height: 125px;
                            border-radius: 10px;
                            position: relative;
                            overflow: hidden;
                            .vlrImg {
                                width: 100%;
                                height: 100%;
                                border-radius: 10px;
                            }
                            .vlrIcon {
                                position: absolute;
                                top: 10px;
                                right: 10px;
                            }
                            .vlrImg:hover {
                                transform: scale(1.05);
                                transition: 0.5s;
                            }
                        }
                        .vlrP {
                            width: 100%;
                            height: 20px;
                            text-align: center;
                            line-height: 20px;
                            font-size: 12px;
                            margin-top: 12px;
                        }
                    }
                }
            }
        }
    }
}
</style>
